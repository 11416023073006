<template>
  <div class="home">
    <youtube
      v-if="videoId"
      :video-id="videoId"
      :player-vars="{ autoplay: 1, muted: 1 }"
      @ready="ready"
      @playing="playing"
    ></youtube>
    <div class="video-list">
      <ul>
        <li
          v-for="(video, index) in videoList"
          :key="index"
          @click="change(video)"
          :class="video === videoId ? 'current' : ''"
        >
          {{ video }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return{
      player: null,
      videoId: null,
      videoList: [
        "Rf36J8nUca8",
        "Cjv635m4w",
        "m_-0JSLvWdo",
        "IyguP0MEMso",
        "_dIquCSLvJM",
        "sZsrpNWj32w",
        "lPbjLASFZjw",
        "VtfUnFecw6k",
        "WVav—CWSlk",
        "oTrTjpX1Gio",
        "H2G8iH07y4I",
        "0Qno-XJwPKw",
        "flUm_-Euy84",
        "BbyZ3d8t_yM",
        "2xFEuginOhE",
        "4fedwVDq4w8",
        "WYKCzN465jY"
      ]
    };
  },
  methods: {
    ready(event) {
      this.player = event.target;
      console.log("player ready");
      this.player.playVideo();
    },
    playing() {
      // The player is playing a video
    },
    change(vid) {
      this.videoId = vid;
    }
  },
  mounted() {
    this.videoId = this.videoList[
      Math.floor(Math.random() * this.videoList.length)
    ];
  }
};
</script>

<style lang="less" scoped>
.video-list {
  max-width: 60vw;
  display: block;
  position: relative;
  margin: 0 auto;

  ul {
    list-style: none;
    position: relative;
    display: flex;
    flex-wrap: wrap;

    li {
      margin: 12px;
      display: block;
      flex-grow: 1;
    }
  }

  .current {
    font-weight: bold;
    color: red;
  }
}
</style>
