import Vue from "vue";
import VueYouTubeEmbed from "vue-youtube-embed";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;
Vue.use(VueYouTubeEmbed);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
